import React, { useState, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Container, Paper, Typography, Button } from '@mui/material';
import FileUploader from '../../../patient-documents/file-uploader/file-uploader-container';
import { isCandor, isPatient } from '../../../../utils/roles';
import { AuthContext } from '../../../../auth-context';

const PREFIX = 'UploadFormElement';

const classes = {
  paper: `${PREFIX}-paper`,
  paperHolder: `${PREFIX}-paperHolder`,
  fullWidthGrid: `${PREFIX}-fullWidthGrid`,
  gridContainer: `${PREFIX}-gridContainer`,
};

const Root = styled('div')(() => ({
  [`& .${classes.paper}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '20px auto',
    maxWidth: '500px',
  },

  [`& .${classes.paperHolder}`]: {
    display: 'flex',
    alignItems: 'stretch',
    width: 'auto',
    justifyContent: 'center',
  },

  [`& .${classes.fullWidthGrid}`]: {
    margin: 30,
  },

  [`& .${classes.gridContainer}`]: {
    justifyContent: 'center',
    minHeight: '70vh',
  },
}));

const UploadFormElement = (props) => {
  const { currentFormObject, handleNext, simpleView } = props;

  const { userType } = useContext(AuthContext);
  const [url, setUrl] = useState('');
  return (
    <Root>
      {((!simpleView && currentFormObject.storagePath !== '') || isPatient(userType) || !userType) && (
        <Container maxWidth="md">
          <Grid container spacing={0} direction="column" className={classes.gridContainer}>
            <Paper className={simpleView ? classes.candorPaper : classes.paper}>
              <Grid container alignItems="center" justifyContent="center" className={classes.paperHolder}>
                <Grid item xs={12} className={classes.fullWidthGrid}>
                  <Typography variant="h6" align="center" gutterBottom>
                    {currentFormObject.headingText}
                  </Typography>
                  <br />
                  {currentFormObject.subheading}
                </Grid>
                <Grid item md={6} className={classes.paperHolder}>
                  <FileUploader url={url} setUrl={setUrl} currentFormObject={currentFormObject} formContext />
                </Grid>
                {!isCandor(userType) && (
                  <Grid item xs={12} align="center" className={classes.fullWidthGrid}>
                    <Button
                      disabled={!currentFormObject.optional && !url}
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                    >
                      Next
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Grid>
        </Container>
      )}
    </Root>
  );
};

export default UploadFormElement;
