import React, { useContext, useState, useEffect } from 'react';
import { doc, updateDoc, deleteField, writeBatch, collection } from 'firebase/firestore';

import { db } from '../../../../firebase-config';
import BasicFormElementPresentation from '../presentation-elements/basic-form-element-presentation';
import { AuthContext } from '../../../../auth-context';
import createEmailObj from '../../../../functions/create-email-obj';
import { isCandor } from '../../../../utils/roles';

const BasicFormElement = (props) => {
  const { currentFormObject, setFormInputs, handleNext, formInputs, formName, index, patientId, simpleView } = props;

  const { user, userType } = useContext(AuthContext);

  const [textValue, setTextValue] = useState('');
  const [formError, setFormError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (value) => {
    setFormError(false);
    setTextValue(value);
  };

  const handleInput = async () => {
    setIsLoading(true);
    const trimmedValue = textValue.trim();

    if (trimmedValue.length < 6 && currentFormObject.maxRows >= 3) {
      setFormError(true);
      setIsLoading(false);
      return;
    }

    if (textValue !== formInputs[currentFormObject.fieldText]) {
      let targetField = currentFormObject.fieldText;
      const obj = { [targetField]: trimmedValue };
      if (user) {
        const userId = userType === 'patient' ? user.uid : patientId;
        if (!isCandor(userType)) obj[`forms.${formName}.step`] = index;
        const effectsFirstName = targetField === 'basic info.first name';
        const effectsLastName = targetField === 'basic info.last name';
        const firstFormCompleted = formInputs['basic info.first form completed'];
        if (!effectsFirstName && !effectsLastName && (!firstFormCompleted || !currentFormObject.notifyDoctor)) {
          updateDoc(doc(db, 'patients', userId, 'general', 'information'), obj);
        } else {
          // For everything else we need to do a batch write
          const batch = writeBatch(db);
          // If first or last name field update the primary doc as well
          if (effectsFirstName || effectsLastName) {
            let strippedField = targetField.replace('basic info.', '');
            strippedField = strippedField.replace(' n', 'N');
            batch.update(doc(db, 'patients', userId), { [strippedField]: trimmedValue });
            batch.update(doc(db, 'patients', userId, 'general', 'information'), obj);
            await batch.commit();
          } else if (firstFormCompleted && currentFormObject.notifyDoctor) {
            // Let GP know that some details were updated if patient has previously been assigned treatment
            targetField = targetField.replace('general health.', '');
            const dataObj = {
              patientName: `${formInputs['basic info.first name']} ${formInputs['basic info.last name']}`,
              email: user.email,
              updatedData: `Patient updated ${targetField}`,
            };
            const mailRef = doc(collection(db, 'mail'));
            batch.set(mailRef, createEmailObj('nurse@candor.org', 'medical_history_updated', dataObj));
            batch.update(doc(db, 'patients', user.uid, 'general', 'information'), obj);
            batch.set(doc(collection(db, 'patients', user.uid, 'activity')), {
              createdAt: Date.now(),
              generalData: false,
              author: 'System',
              text: `Patient updated their ${targetField}${formInputs[currentFormObject.fieldText] === undefined ? '' : ` from: [${formInputs[currentFormObject.fieldText]}]`} to: [${trimmedValue}]`,
            });
            await batch.commit();
          }
        }
      }
      setFormInputs((fi) => ({ ...fi, ...obj }));
    }
    setIsLoading(false);
    handleNext();
  };

  const handleSkip = () => {
    if (textValue !== formInputs[currentFormObject.fieldText]) {
      const formInputsCopy = formInputs;
      delete formInputsCopy[currentFormObject.fieldText];
      setFormInputs(formInputsCopy);
      updateDoc(doc(db, 'patients', user?.uid, 'general', 'information'), {
        [currentFormObject.fieldText]: deleteField(),
      });
    }
    handleNext();
  };

  useEffect(() => {
    setTextValue(formInputs[currentFormObject.fieldText]);
  }, [currentFormObject, formInputs, formName]);

  return (
    <BasicFormElementPresentation
      isLoading={isLoading}
      formError={formError}
      handleSkip={handleSkip}
      simpleView={simpleView}
      currentFormObject={currentFormObject}
      inputValue={textValue}
      handleInput={handleInput}
      handleChange={handleChange}
    />
  );
};

export default BasicFormElement;
