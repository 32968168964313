
import moment from 'moment';

const SCHEDULE_8_SCRIPT_VALIDITY_IN_MONTHS = 6;
const OTHER_SCRIPTS_VALIDITY_IN_MONTHS = 12;

export const validateInternationalPhoneNumbers = (number) => {
  // if number doesn't have + at the start, add it.
  const formattedNumber = number[0] === '+' ? number : `+${number}`;
  // Regular expression for international phone numbers
  const phoneRegex = /^\+(?:[0-9] ?){6,14}[0-9]$/;
  return phoneRegex.test(formattedNumber);
};

/**
 * Function to check if a script has expired.
 * @param {number} createdEpoch - The epoch time when the script was created.
 * @param {number} expiryMonths - The number of months after which the script expires.
 * @returns {boolean} - True if the script has expired, false otherwise.
 */
export function isScriptExpired({ createdEpoch, expiryMonths }) {
  const createdDate = moment(createdEpoch);
  const expiryDate = createdDate.clone().add(expiryMonths, 'months').endOf('day');
  return moment().isAfter(expiryDate);
};

/**
 * Function to get the expiry date of a prescription.
 * @param {number} createdEpoch - The epoch time when the prescription was created.
 * @param {number} expiryMonths - The number of months after which the prescription expires.
 * @returns {string} - The expiry date of the prescription in DATE format.
 */
export function getExpiryDate({ createdEpoch, expiryMonths }) {
  const createdDate = moment(createdEpoch);
  const expiryDate = createdDate.clone().add(expiryMonths, 'months');
  return expiryDate.format('DD/MM/YY');
};

/**
 * Determines the validity period of a prescription based off our catalogue categories.
 * @param {string} catalogueCategory - The TGA Category of the prescription.
 * @returns {number} - The number of months the prescription is valid for.
 */
export const getScriptValidityInMonths = (catalogueCategory) => {
  switch (catalogueCategory) {
    case 'medicinal cannabis':
      return SCHEDULE_8_SCRIPT_VALIDITY_IN_MONTHS;
    default:
      return OTHER_SCRIPTS_VALIDITY_IN_MONTHS;
  }
};
