import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import ResetPasswordInput from '../login/auth-landing-pages/reset-password-input';
import ResetPasswordLinkError from '../login/auth-landing-pages/reset-password-link-error';
import AuthLandingPageSuccess from '../login/auth-landing-pages/auth-landing-page-success';
import WeightLossForm from '../form/standard-forms/weight-loss-form';

const AuthLandingPageContainer = lazy(() => import('../login/auth-landing-pages/auth-landing-page-container'));
const EmailAuthCards = lazy(() => import('../login/email-auth-container'));
const HairLossForm = lazy(() => import('../form/standard-forms/hair-loss-form'));
const SleepForm = lazy(() => import('../form/standard-forms/sleep-form'));
const ColdSoreForm = lazy(() => import('../form/standard-forms/cold-sores-form'));
const HerpesForm = lazy(() => import('../form/standard-forms/herpes-form'));
const AcneForm = lazy(() => import('../form/standard-forms/acne-form'));
const AgeingForm = lazy(() => import('../form/standard-forms/ageing-form'));
const AllergiesForm = lazy(() => import('../form/standard-forms/allergies-form'));
const ErectileDysfunctionForm = lazy(() => import('../form/standard-forms/erectile-dysfunction-form'));
const ContraceptivePillForm = lazy(() => import('../form/standard-forms/contraceptive-pill-form'));
const MedicinalCannabisForm = lazy(() => import('../form/standard-forms/medicinal-cannabis-form'));
const AccountCreation = lazy(() => import('../AccountCreation/AccountCreationContainer'));

const UnauthenticatedRouter = () => (
  <Routes>
    <Route path="/medicinal-cannabis-form" element={<MedicinalCannabisForm />} />
    <Route path="/hair-loss-form" element={<HairLossForm />} />
    <Route path="/weight-loss-form" element={<WeightLossForm />} />
    <Route path="/sleep-form" element={<SleepForm />} />
    <Route path="/cold-sores-form" element={<ColdSoreForm />} />
    <Route path="/herpes-form" element={<HerpesForm />} />
    <Route path="/acne-form" element={<AcneForm />} />
    <Route path="/ageing-form" element={<AgeingForm />} />
    <Route path="/allergies-form" element={<AllergiesForm />} />
    <Route path="/erectile-dysfunction-form" element={<ErectileDysfunctionForm />} />
    <Route path="/contraceptive-pill-form" element={<ContraceptivePillForm />} />
    <Route path="/pharmacy-creation" element={<AccountCreation isPharmacy />} />
    <Route path="/doctor-creation" element={<AccountCreation isDoctor />} />
    <Route path="/supplier-creation" element={<AccountCreation isSupplier />} />
    <Route path="/auth-landing-page" element={<AuthLandingPageContainer />} />
    <Route path="/reset-password-input" element={<ResetPasswordInput />} />
    <Route path="/auth-landing-page-success" element={<AuthLandingPageSuccess />} />
    <Route path="/reset-password-link-error" element={<ResetPasswordLinkError />} />
    <Route path="/" element={<EmailAuthCards quickLogin />} />
    <Route path="/email-preferences" element={<EmailAuthCards quickLogin />} />
    <Route path="/*" element={<EmailAuthCards quickLogin />} />
  </Routes>
);

export default UnauthenticatedRouter;
