import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Paper, Container, Typography, Button, Link } from '@mui/material';
import CustomizableSnackbar from '../../../layout/snackbar';

const PREFIX = 'SubmitFormElementPresentation';

const classes = {
  container: `${PREFIX}-container`,
  gridContainer: `${PREFIX}-gridContainer`,
  paper: `${PREFIX}-paper`,
  heading: `${PREFIX}-heading`,
  finalButton: `${PREFIX}-finalButton`,
};

const StyledContainer = styled(Container)(() => ({
  [`& .${classes.container}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.gridContainer}`]: {
    minHeight: '60vh',
    marginTop: 40,
  },

  [`& .${classes.paper}`]: {
    padding: '40px',
    width: '85%',
    maxWidth: '500px',
    '@media (max-width: 600px)': {
      padding: '30px',
    },
  },

  [`& .${classes.heading}`]: {
    marginBottom: 30,
  },

  [`& .${classes.finalButton}`]: {
    margin: '30px auto 10px auto',
    width: '150px',
    display: 'block',
    backgroundColor: '#303F56',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#61718c',
    },
  },
}));

const AGREEMENT_LINK = 'https://www.candor.org/terms-and-conditions';
const AGREEMENT_LINK_TEXT = 'terms & conditions';

const AgreementText = () => (
  <Typography variant="body1" align="center" gutterBottom>
    {`By clicking 'submit form' you agree that you have read and understand our `}
    <Link underline="none" target="blank" href={AGREEMENT_LINK}>
      {AGREEMENT_LINK_TEXT}
    </Link>
    {` and that the information you have provided is true and correct.`}
  </Typography>
);

const SubmitFormElementPresentation = (props) => {
  const { handleSubmit, snackbarOpen, setSnackbarOpen, formName, formInputs, stripePrice } = props;

  return (
    <StyledContainer maxWidth="lg">
      <Grid
        container
        className={classes.gridContainer}
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Paper className={classes.paper}>
          <form>
            <div>
              <Typography className={classes.heading} variant="h5" align="center" gutterBottom>
                Form Complete
              </Typography>
            </div>
            <div>
              <Typography variant="body1" align="center" gutterBottom>
                Nice work! Your form is complete.
                <br />
                {stripePrice !== null
                  ? 'Click below to submit your form and proceed to payment.'
                  : 'Click submit to send your form to your Candor GP.'}
              </Typography>
              <br />
              {!formInputs['basic info.first form completed'] && <AgreementText />}
              <Typography variant="body1" align="center" gutterBottom>
                Please note: Once you submit your form you will not be able to change your responses.
              </Typography>
            </div>
            <div>
              <Button className={classes.finalButton} variant="contained" color="primary" onClick={handleSubmit}>
                Submit Form
              </Button>
            </div>
          </form>
        </Paper>
      </Grid>
      <CustomizableSnackbar message="Submitting Form" snackbarOpen={snackbarOpen} setSnackbarOpen={setSnackbarOpen} />
    </StyledContainer>
  );
};

export default SubmitFormElementPresentation;
