import React from 'react';
import { Grid, Typography } from '@mui/material';

const CapsuleInfo = () => (
  <Grid item>
    <Typography variant="h6">Taking medicinal cannabis capsules</Typography>
    <br />
    <Typography variant="body1">
      Medicinal cannabis oil capsules are simple and easy to use. Capsules can be taken with or without food. Cannatrek
      cannabis capsules are designed for oral administration and are dosed per capsule. Many patients prefer cannabis
      capsules due to convenience and accuracy of dosing.
      <br />
      <br />
      Some patients may experience benefits after their first dose, while others may take 2-4 weeks to experience
      medicinal benefits.
      <br />
      <br />
      Titration is important when using cannabis oil capsules, especially for new patients. Oral cannabis takes 1-3
      hours to have an effect and lasts for 6-8 hours. We suggest waiting at least 6 hours between doses. Dosing
      schedules differ for each patient and should be discussed with your doctor. Doses should be increased gradually
      over time and the effect fully assessed before continuing to increase. Note that consuming too much cannabis can
      result in discomfort and should be avoided.
    </Typography>
    <br />
  </Grid>
);

export default CapsuleInfo;
