import React, { useContext, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import { Grid, Paper, Button, Container, Typography, Alert, FormControlLabel, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import { AuthContext } from '../../../../auth-context';
import { getFormsButtonText } from '../../../../utils/button-messages';
import { isCandor } from '../../../../utils/roles';

const PREFIX = 'HealthCardFormElementPresentation';

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
  gridContainer: `${PREFIX}-gridContainer`,
  accountGrid: `${PREFIX}-accountGrid`,
  paper: `${PREFIX}-paper`,
  heading: `${PREFIX}-heading`,
  subheading: `${PREFIX}-subheading`,
  input: `${PREFIX}-input`,
  form: `${PREFIX}-form`,
  label: `${PREFIX}-label`,
  checkbox: `${PREFIX}-checkbox`,
  button: `${PREFIX}-button`,
  inputGrid: `${PREFIX}-inputGrid`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`& .${classes.container}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyConter: 'center',
  },

  [`& .${classes.gridContainer}`]: {
    flexWrap: 'nowrap',
    minHeight: '60vh',
    alignItems: 'center',
    justifyContent: 'center',
    direction: 'column',
    marginBottom: '40px',
    marginTop: 40,
  },

  [`& .${classes.accountGrid}`]: {
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    direction: 'column',
    margin: '20px 0px',
  },

  [`& .${classes.paper}`]: {
    padding: '40px',
    width: '85%',
    maxWidth: '550px',
    '@media (max-width: 600px)': {
      padding: '30px',
    },
  },

  [`& .${classes.heading}`]: {
    marginBottom: 30,
  },

  [`& .${classes.subheading}`]: {
    margin: '-10px 0px 20px 0px',
  },

  [`& .${classes.input}`]: {
    margin: '20px 0px',
    width: '300px',
  },

  [`& .${classes.form}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
  },

  [`& .${classes.label}`]: {
    margin: '10px 0px',
    padding: '10px',
    textAlign: 'center',
    width: '310px',
    [theme.breakpoints.down('xl')]: {
      width: '280px',
    },
  },

  [`& .${classes.checkbox}`]: {
    display: 'none',
  },

  [`& .${classes.button}`]: {
    margin: '30px auto 10px auto',
    width: '150px',
    display: 'block',
  },

  [`& .${classes.inputGrid}`]: {
    padding: '10px 5px ',
  },
}));

const StyledFormControlLabel = FormControlLabel;

const HealthCardFormElementPresentation = (props) => {
  const {
    currentFormObject,
    handleInput,
    handleChange,
    checkedOptions,
    isUpdateDetails,
    healthCardData,
    crnError,
    expiryError,
    setCrnError,
    address1Error,
    address2Error,
    startDateError,
    validHealthCardFields,
    firstNameError,
    lastNameError,
    handleDate,
  } = props;

  const { userType } = useContext(AuthContext);
  const [startDate, setStartDate] = useState(null);
  const [expiryData, setExpiryData] = useState(null);
  useEffect(() => {
    if (!healthCardData?.startDate) {
      setStartDate(null);
    } else {
      setStartDate(moment(healthCardData?.startDate));
    }
    if (!healthCardData?.expiry) {
      setExpiryData(null);
    } else {
      setExpiryData(moment(healthCardData?.expiry));
    }
  }, [healthCardData]);

  const buttonText = getFormsButtonText({ isUpdateDetails, isLoading: false });

  return (
    <StyledContainer maxWidth="lg">
      <Grid container spacing={0} className={isCandor(userType) ? classes.accountGrid : classes.gridContainer}>
        <Paper className={classes.paper}>
          <Grid container spacing={2} className={classes.container}>
            <Grid item xs={12}>
              <Typography className={classes.heading} variant="h5" align="center" gutterBottom>
                {currentFormObject.headingText}
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.inputGrid}>
              <TextField
                label="First Name"
                value={healthCardData?.firstName || ''}
                onChange={(e) => handleChange(e.target.value, 'firstName')}
                variant="outlined"
                fullWidth
                error={firstNameError}
              />
            </Grid>
            <Grid item xs={6} className={classes.inputGrid}>
              <TextField
                label="Last Name"
                value={healthCardData?.lastName || ''}
                onChange={(e) => handleChange(e.target.value, 'lastName')}
                variant="outlined"
                fullWidth
                error={lastNameError}
              />
            </Grid>

            <Grid item xs={12} className={classes.inputGrid}>
              <TextField
                label="First Line Address"
                value={healthCardData?.address1 || ''}
                onChange={(e) => handleChange(e.target.value, 'address1')}
                variant="outlined"
                fullWidth
                error={address1Error}
              />
            </Grid>

            <Grid item xs={12} className={classes.inputGrid}>
              <TextField
                label="Second Line Address"
                value={healthCardData?.address2 || ''}
                onChange={(e) => handleChange(e.target.value, 'address2')}
                variant="outlined"
                fullWidth
                error={address2Error}
              />
            </Grid>
            <Grid item xs={12} className={classes.inputGrid}>
              <TextField
                label="Customer Reference Reference (CRN)"
                value={healthCardData?.crn || ''}
                onChange={(e) =>
                  handleChange(
                    e.target.value
                      .replace(/\s+/g, '')
                      .replace(/[^\w\s]/g, '')
                      .toUpperCase(),
                    'crn',
                  )
                }
                variant="outlined"
                inputProps={{ maxLength: 13 }}
                onBlur={(e) => {
                  if (e.target.value.replace(/\s+/g, '').replace(/[^\w\s]/g, '').length < 9) setCrnError(true);
                }}
                fullWidth
                error={crnError}
                errorMessage="Incorrect CRN, A CRN is 9 numbers and ends with a letter. For example, 123 456 789A."
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.inputGrid}>
              <DatePicker
                className={classes.datePicker}
                label="Start Date"
                maxDate={moment()}
                value={startDate}
                inputFormat="DD/MM/YYYY"
                onChange={(date) => handleDate(date, 'startDate')}
                /* eslint-disable react/jsx-props-no-spreading */
                renderInput={(params) => <TextField {...params} fullWidth error={startDateError} />}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.inputGrid}>
              <DatePicker
                className={classes.datePicker}
                label="Expiry Date"
                minDate={moment().add(1, 'days')}
                value={expiryData}
                inputFormat="DD/MM/YYYY"
                onChange={(date) => handleDate(date, 'expiry')}
                /* eslint-disable react/jsx-props-no-spreading */
                renderInput={(params) => <TextField {...params} fullWidth error={expiryError} />}
              />
            </Grid>

            <Grid item xs={12} className={classes.inputGrid}>
              {firstNameError && <Alert severity="error">Please enter a valid first name</Alert>}
              {lastNameError && <Alert severity="error">Please enter a valid last name</Alert>}
              {address1Error && <Alert severity="error">Please enter a valid first line address</Alert>}
              {address2Error && <Alert severity="error">Please enter a valid second line address</Alert>}
              {crnError && (
                <Alert severity="error">
                  Incorrect CRN format. A CRN is 9 numbers and ends with a letter. For example, 123 456 789A.
                </Alert>
              )}
              {startDateError && <Alert severity="error">Please enter valid start date</Alert>}
              {expiryError && <Alert severity="error">Please enter valid expiry date</Alert>}
            </Grid>
          </Grid>
          <Button
            disabled={
              !validHealthCardFields || (currentFormObject.selectOne && !Object.values(checkedOptions).includes(true))
            }
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={handleInput}
          >
            {buttonText}
          </Button>
        </Paper>
      </Grid>
    </StyledContainer>
  );
};

export default HealthCardFormElementPresentation;
