import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import {
  Grid,
  Paper,
  Button,
  Container,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Alert,
} from '@mui/material';
import PropTypes from 'prop-types';

import { isCandor } from '../../../../utils/roles';
import { AuthContext } from '../../../../auth-context';
import GoogleAddressAutocomplete from '../../../shipping-information/google-address-autocomplete';
import { getFormsButtonText } from '../../../../utils/button-messages';
import CustomPhoneNumber from '../../../layout/customPhoneNumber';

const PREFIX = 'ShippingFormElementPresentation';

const classes = {
  container: `${PREFIX}-container`,
  gridContainer: `${PREFIX}-gridContainer`,
  accountGrid: `${PREFIX}-accountGrid`,
  paper: `${PREFIX}-paper`,
  heading: `${PREFIX}-heading`,
  subheading: `${PREFIX}-subheading`,
  input: `${PREFIX}-input`,
  form: `${PREFIX}-form`,
  label: `${PREFIX}-label`,
  checkbox: `${PREFIX}-checkbox`,
  button: `${PREFIX}-button`
};

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`& .${classes.container}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyConter: 'center',
  },

  [`& .${classes.gridContainer}`]: {
    flexWrap: 'nowrap',
    minHeight: '60vh',
    alignItems: 'center',
    justifyContent: 'center',
    direction: 'column',
    marginBottom: '40px',
    marginTop: 40,
  },

  [`& .${classes.accountGrid}`]: {
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    direction: 'column',
    margin: '20px 0px',
  },

  [`& .${classes.paper}`]: {
    padding: '40px',
    width: '85%',
    maxWidth: '500px',
    '@media (max-width: 600px)': {
      padding: '30px',
    },
  },

  [`& .${classes.heading}`]: {
    marginBottom: 30,
  },

  [`& .${classes.subheading}`]: {
    margin: '-10px 0px 20px 0px',
  },

  [`& .${classes.input}`]: {
    margin: '20px 0px',
    width: '300px',
  },

  [`& .${classes.form}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
  },

  [`& .${classes.label}`]: {
    margin: '10px 0px',
    padding: '10px',
    textAlign: 'center',
    width: '310px',
    [theme.breakpoints.down('xl')]: {
      width: '280px',
    },
  },

  [`& .${classes.checkbox}`]: {
    display: 'none',
  },

  [`& .${classes.button}`]: {
    margin: '30px auto 10px auto',
    width: '130px',
    display: 'block',
  }
}));

const ShippingFormElementPresentation = (props) => {
  const { currentFormObject, handleInput, handleChange, shippingData, isPhoneError } = props;
  const { userType } = useContext(AuthContext);


  const [isUsingGoogleAddress, setIsUsingGoogleAddress] = React.useState(true);
  const buttonText = getFormsButtonText({ isUpdateDetails: false, isLoading: false });

  return (
    <StyledContainer maxWidth="lg">
      <Grid container spacing={0} className={isCandor(userType) ? classes.accountGrid : classes.gridContainer}>
        <Paper className={classes.paper}>
          <Grid container spacing={2} className={classes.container}>
            <Grid item xs={12}>
              <Typography className={classes.heading} variant="h5" align="center" gutterBottom>
                Your Contact Information
              </Typography>
            </Grid>
            {isUsingGoogleAddress && (
              <>
                <Grid item xs={12} className={classes.inputGrid}>
                  <GoogleAddressAutocomplete
                    handleChange={handleChange}
                    setIsUsingGoogleAddress={setIsUsingGoogleAddress}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={classes.inputGrid}
                  sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  <Button fullWidth onClick={() => setIsUsingGoogleAddress(false)}>
                    Manually Enter Address
                  </Button>
                </Grid>
              </>
            )}
            {!isUsingGoogleAddress && (
              <Box component="form" onSubmit={handleInput}>
                <Grid container spacing={2} className={classes.container}>
                  <Grid item xs={12} sm={6} className={classes.inputGrid}>
                    <TextField
                      label="Street Address"
                      value={shippingData['street address'] || ''}
                      onChange={(e) => handleChange(e.target.value, 'street address')}
                      variant="outlined"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} className={classes.inputGrid}>
                    <TextField
                      label="Suburb"
                      value={shippingData.suburb || ''}
                      onChange={(e) => handleChange(e.target.value, 'suburb')}
                      variant="outlined"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={6} sm={3} className={classes.inputGrid}>
                    <TextField
                      label="Postcode"
                      value={shippingData.postcode || ''}
                      onChange={(e) => handleChange(e.target.value, 'postcode')}
                      variant="outlined"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={6} sm={3} className={classes.inputGrid}>
                    <FormControl fullWidth required>
                      <InputLabel id="state-selector-label">State</InputLabel>
                      <Select
                        labelId="state-selector-label"
                        id="state-selector"
                        label="State"
                        value={shippingData.state || ''}
                        onChange={(e) => handleChange(e.target.value, 'state')}
                        required
                      >
                        <MenuItem value="ACT">ACT</MenuItem>
                        <MenuItem value="NSW">NSW</MenuItem>
                        <MenuItem value="NT">NT</MenuItem>
                        <MenuItem value="SA">SA</MenuItem>
                        <MenuItem value="TAS">TAS</MenuItem>
                        <MenuItem value="QLD">QLD</MenuItem>
                        <MenuItem value="VIC">VIC</MenuItem>
                        <MenuItem value="WA">WA</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} className={classes.inputGrid}>
                    <CustomPhoneNumber
                      phoneValue={shippingData.phone || ''}
                      onChangePhoneValue={(value) => handleChange(value, 'phone')}
                      isPhoneError={isPhoneError}
                    />
                  </Grid>

                  <Grid item xs={12} className={classes.inputGrid}>
                    {isPhoneError && <Alert severity="error">Valid phone number is required. ie. 614 XXXX XXXX</Alert>}
                  </Grid>
                  {!isCandor(userType) && (
                    <Box sx={{ display: 'flex', width: '100%' }}>
                      <Button
                        disabled={currentFormObject.selectOne}
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        {buttonText}
                      </Button>
                    </Box>
                  )}
                </Grid>
              </Box>
            )}
          </Grid>
        </Paper>
      </Grid>
    </StyledContainer>
  );
};

ShippingFormElementPresentation.propTypes = {
  handleInput: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  isPhoneError: PropTypes.bool.isRequired,
  shippingData: PropTypes.shape({
    phone: PropTypes.string.isRequired,
    postcode: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    'street address': PropTypes.string.isRequired,
    suburb: PropTypes.string.isRequired,
  }).isRequired,
};

export default ShippingFormElementPresentation;
