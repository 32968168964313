import React, { useState, useEffect, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Paper } from '@mui/material';
import { getDoc, doc, writeBatch, deleteField } from 'firebase/firestore';

import { AuthContext } from '../../auth-context';
import { db } from '../../firebase-config';
import PaymentDetails from './payment-details';
import NovattiPayment from './novatti-ui/novatti-payment';
import { getCatalogueId } from '../../utils/constants';

const PREFIX = 'payment-container';

const classes = {
  modalGrid: `${PREFIX}-modalGrid`,
  novattiPaper: `${PREFIX}-novattiPaper`,
  checkoutGrid: `${PREFIX}-checkoutGrid`,
  novattiGrid: `${PREFIX}-novattiGrid`,
};

const StyledGrid = styled(Grid)(() => ({
  textAlign: 'center',
  borderRadius: '5px',
  maxHeight: '95dvh',

  width: '100%',
  padding: '0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',

  [`& .${classes.novattiPaper}`]: {
    margin: '10px 0 10px 0',
    width: '100%',
    // height: '100%',
    maxWidth: '1300px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#2AAFBB',
      borderRadius: '100px',
    },
  },

  [`& .${classes.checkoutGrid}`]: {
    padding: '30px',
    width: '100%',
  },

  [`& .${classes.novattiGrid}`]: {
    '@media (max-width: 900px)': {
      margin: '-60px auto 10px auto',
    },
    margin: '5px auto',
  },
}));

const PaymentContainer = ({
  selectedTreatments,
  formName,
  scriptMode,
  type,
  pharmacies,
  description = 'No description provided',
  setCollectingCardInfo,
  formatShippingDetails,
  pharmacyConsentRead,
  updatePreferredPharmacy,
  isPickup,
  isPharmacyPending,
  pharmacySelectionMode,
}) => {
  const { user } = useContext(AuthContext);
  const [isCheckoutLoading, setIsCheckoutLoading] = useState(false);
  const [checkoutData, setCheckoutData] = useState({});
  const [shippingAlert, setShippingAlert] = useState('');
  const [promoCode, setPromoCode] = useState('');
  const [showPaymentMethods, setShowPaymentMethods] = useState(false);
  const [termsRead, setTermsRead] = useState(false);
  const [userData, setUserData] = useState({});
  const [treatmentsToRead, setTreatmentsToRead] = useState([]);
  const [treatments, setTreatments] = useState([]);

  const handleTermsRead = () => {
    const shippingDetails = formatShippingDetails();

    if (termsRead) {
      setTermsRead(false);
      // Make sure all shipping details have been entered
    } else if (!scriptMode && Object.values(shippingDetails).includes('')) {
      setShippingAlert('Shipping Details Missing');
      // Check that street address contains both a number and a street name
    } else if (!scriptMode && !shippingDetails['shipping.street address'].match(/\d/)) {
      setShippingAlert('Street Address Missing Number');
    } else if (!scriptMode && !shippingDetails['shipping.street address'].match(/[a-zA-Z]/)) {
      setShippingAlert('Street Address Missing Street Name');
    } else {
      setTermsRead(true);
      setShippingAlert('');
    }
  };

  const handlePaymentButton = async () => {
    if (isPharmacyPending) {
      window.alert(
        'Please select a pharmacy or set the dispatch pharmacy mode to "Auto" instead of "Manual Selection".',
      );
      return;
    }

    if (type !== 'consult' && type !== 'booking fee' && type !== 'substitution fee') {
      if (scriptMode) updatePreferredPharmacy();
      // Lock the shipping info fields
      setCollectingCardInfo(true);
      // Add current shipping info the the update object
      const dataUpdate = formatShippingDetails();
      const generalInfoDataUpdate = formatShippingDetails(true);
      const batch = writeBatch(db);

      treatments.forEach(async (treatment) => {
        dataUpdate[
          `acceptedAgreements.${formName === 'medicinal cannabis' ? treatment.dosageForm : treatment.ingredients}`
        ] = Date.now();
      });

      if (pharmacySelectionMode === 'automatic') {
        // Remmove the next pickup and next delivery fields so novatti will run the pharmacySelector instead
        batch.update(doc(db, 'patients', user.uid), {
          ...dataUpdate,
          nextPickup: deleteField(),
          nextDelivery: deleteField(),
        });
      } else if (isPickup) {
        // If the user is picking up, remove the next delivery field
        batch.update(doc(db, 'patients', user.uid), { ...dataUpdate, nextDelivery: deleteField() });
      } else if (!isPickup) {
        // If the user is getting delivery, remove the next pickup field
        batch.update(doc(db, 'patients', user.uid), { ...dataUpdate, nextPickup: deleteField() });
      }
      batch.update(doc(db, 'patients', user.uid, 'general', 'information'), generalInfoDataUpdate);
      await batch.commit();
    }

    setIsCheckoutLoading(true);
    setShowPaymentMethods(true);
  };

  useEffect(() => {
    const getTreatmentTranslator = async () => {
      if (selectedTreatments[0]) {
        const promises = selectedTreatments.map(async (value) => {
          const treatment = await getDoc(doc(db, 'catalogue', getCatalogueId(value)));
          return treatment.data();
        });

        const u = await getDoc(doc(db, 'patients', user?.uid));
        const result = await Promise.all(promises);
        const toRead = result.filter(
          (item) =>
            !Object.keys(u?.data()?.acceptedAgreements || {}).includes(
              formName === 'medicinal cannabis' ? item.dosageForm : item.ingredients,
            ),
        );
        const tempTreatmentTranslator = {};
        result.forEach((item) => {
          tempTreatmentTranslator[item.stripePrice] = item;
        });

        if (toRead.length === 0) {
          setTermsRead(true);
        }

        setUserData(u.data());
        setTreatments(result);
        setTreatmentsToRead(toRead.map((item) => item.stripePrice));
      }
    };
    getTreatmentTranslator();
  }, [checkoutData, selectedTreatments, user, formName]);

  return (
    <StyledGrid className={classes.modalGrid} data-testid="payment-container-modal">
      <Paper className={classes.novattiPaper}>
        <Grid container>
          <Grid item className={classes.checkoutGrid} xs>
            <PaymentDetails
              userData={userData}
              treatmentsToRead={treatmentsToRead}
              selectedTreatments={selectedTreatments}
              handlePaymentButton={handlePaymentButton}
              isCheckoutLoading={isCheckoutLoading}
              termsRead={termsRead}
              description={description}
              pharmacies={pharmacies}
              checkoutData={checkoutData}
              setCheckoutData={setCheckoutData}
              formName={formName}
              scriptMode={scriptMode}
              handleTermsRead={handleTermsRead}
              shippingAlert={shippingAlert}
              promoCode={promoCode}
              setPromoCode={setPromoCode}
              pharmacyConsentRead={pharmacyConsentRead}
              isPickup={isPickup}
              isPharmacyPending={isPharmacyPending}
            />
          </Grid>
          {showPaymentMethods && (
            <Grid item xs={12} md={6} className={classes.novattiGrid}>
              <NovattiPayment
                selectedTreatments={selectedTreatments}
                isPickup={isPickup}
                scriptMode={scriptMode}
                formName={formName}
                type={type}
                promoCode={checkoutData.discount > 0 ? promoCode : ''}
              />
            </Grid>
          )}
        </Grid>
      </Paper>
    </StyledGrid>
  );
};

export default PaymentContainer;
