import React, { useState, useEffect, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Button, CircularProgress, Divider, Grid, Typography, Checkbox, Modal, TextField } from '@mui/material';
import { doc, getDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { captureMessage as sentryCaptureMessage } from '@sentry/react';

import { AuthContext } from '../../auth-context';
import { db, functions } from '../../firebase-config';
import InformationModal from '../prescriptions/information-modal';
import { scriptPriceTranslator } from '../../utils/form-translators';

const PREFIX = 'PaymentDetails';

const classes = {
  root: `${PREFIX}-root`,
  paymentDetails: `${PREFIX}-paymentDetails`,
  paymentItem: `${PREFIX}-paymentItem`,
  paymentTotal: `${PREFIX}-paymentTotal`,
  termsText: `${PREFIX}-termsText`,
  modalGrid: `${PREFIX}-modalGrid`,
  shippingAlert: `${PREFIX}-shippingAlert`,
  promoCode: `${PREFIX}-promoCode`,
};

const StyledGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',

  [`& .${classes.paymentDetails}`]: {
    margin: '1rem 0',
    padding: '1rem 0',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    height: '100%',
    width: '100%',
  },

  [`& .${classes.paymentItem}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 4,
  },

  [`& .${classes.paymentTotal}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0.5rem 0',
  },

  [`& .${classes.termsText}`]: {
    marginBottom: 20,
  },

  [`& .${classes.modalGrid}`]: {
    margin: 40,
    '@media (max-width: 600px)': {
      margin: 30,
    },
  },

  [`& .${classes.shippingAlert}`]: {
    margin: '-20px 0px 20px 0px',
  },

  [`& .${classes.promoCode}`]: {
    display: 'flex',
    alignSelf: 'start',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 8,
    margin: '5px 0px',
  },
}));

const StyledModal = styled(Modal)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
}));

const LowerCaseButton = styled(Button)(() => ({
  textTransform: 'none',
}));

const convertToCurrency = (amount) =>
  `$${Number(amount)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;

// Needs a thorough refactor
const PaymentDetails = ({
  treatmentsToRead,
  selectedTreatments,
  handlePaymentButton,
  isCheckoutLoading,
  isPaymentLoading,
  termsRead,
  description,
  checkoutData,
  setCheckoutData,
  handleTermsRead,
  scriptMode,
  formName,
  shippingAlert,
  promoCode,
  setPromoCode,
  pharmacyConsentRead,
  isPickup,
  isPharmacyPending,
}) => {
  const { user } = useContext(AuthContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [isUsingPromoCode, setIsUsingPromoCode] = useState(false);
  const [isPromoLoading, setIsPromoLoading] = useState(false);

  const { creditCardFee, discount, discountCode, appliedCredit, finalAmount, shippingCharge, gst, products } =
    checkoutData || {};
  const isConsultation = products?.[0]?.name === 'consultation' || products?.[0]?.name === 'booking fee';
  const [promoInput, setPromoInput] = useState('');
  const [treatmentTranslator, setTreatmentTranslator] = useState({});
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsPromoLoading(true);
    const getCheckoutData = async () => {
      try {
        const { data } = await httpsCallable(
          functions,
          'getCheckoutData',
        )({
          selectedTreatments: scriptMode ? [scriptPriceTranslator[formName]] : selectedTreatments,
          discountCode: promoCode,
          userId: user?.uid,
          isPickup,
        });
        if (data?.error) {
          throw new Error(data?.error);
        }

        setCheckoutData(data);
        setIsPromoLoading(false);
      } catch ({ message }) {
        setIsError(true);
        sentryCaptureMessage(message, { level: 'error', user: { selectedTreatments, scriptMode, formName } });
      }
    };
    if (selectedTreatments) getCheckoutData();
  }, [selectedTreatments, setCheckoutData, scriptMode, formName, promoCode, user, isPickup]);

  useEffect(() => {
    const getTreatmentTranslator = async () => {
      try {
        if (products) {
          const promises = products.map(async (value) => {
            const treatment = await getDoc(doc(db, 'catalogue', value.stripePrice.replace('price_', '')));
            return treatment.data();
          });

          const result = await Promise.all(promises);
          const tempTreatmentTranslator = {};
          result.forEach((item) => {
            tempTreatmentTranslator[item.stripePrice] = item;
          });

          setTreatmentTranslator(tempTreatmentTranslator);
        }
      } catch ({ message }) {
        setIsError(true);
        sentryCaptureMessage(message, { level: 'error', user: { selectedTreatments, scriptMode, formName } });
      }
    };
    getTreatmentTranslator();
  }, [formName, products, scriptMode, selectedTreatments]);

  const applyPromoCode = () => {
    setPromoCode(promoInput);
    setIsPromoLoading(true);
  };

  // get products name, quanty and total price display
  const checkoutProducts = () => {
    const newData = [];
    products.forEach((item) => {
      const newItem = {
        stripePrice: '',
        data: '',
        qty: 0,
      };
      const foundIndex = newData.findIndex((x) => x.stripePrice === item.stripePrice);
      if (foundIndex === -1) {
        newItem.stripePrice = item.stripePrice;
        newItem.data = item;
        newItem.qty = 1;
        newData.push(newItem);
      } else {
        newData[foundIndex].qty += 1;
      }
    });
    return newData;
  };

  if (isError) {
    return (
      <StyledGrid>
        <Typography fontWeight="bold" color="secondary">
          Oh snap there is an Error! Please refresh the page.
          <br />
          Sorry for the inconvenience.
        </Typography>
      </StyledGrid>
    );
  }

  return (
    <StyledGrid className={classes.modalGrid} data-testid="payment-details-modal">
      <Typography variant="h5" gutterBottom>
        Payment Details
      </Typography>
      {products && Object.keys(treatmentTranslator || {}).length !== 0 && (
        <>
          <Box className={classes.paymentDetails}>
            {checkoutProducts().map((product) => {
              const price = product.data.gst ? product.data.price / 1.1 : product.data.price;
              return (
                <Box key={product.stripePrice} className={classes.paymentItem}>
                  <Typography>{`${scriptMode ? 'Script Admin Fee' : treatmentTranslator[product.stripePrice]?.item ?? description} x ${product.qty}`}</Typography>
                  <Typography>{convertToCurrency(price * product.qty)}</Typography>
                </Box>
              );
            })}
            <Divider
              sx={{
                opacity: '0.6',
              }}
            />
            <Box className={classes.paymentItem}>
              <Typography>Shipping Fee</Typography>
              <Typography>{convertToCurrency(shippingCharge)}</Typography>
            </Box>
            <Box className={classes.paymentItem}>
              <Typography>Card Surcharge</Typography>
              <Typography>{convertToCurrency(creditCardFee)}</Typography>
            </Box>
            <Divider />
            <Box className={classes.paymentItem}>
              <Typography>GST</Typography>
              <Typography>{convertToCurrency(gst || 0)}</Typography>
            </Box>
            {discount !== 0 && (
              <Box className={classes.paymentItem}>
                <Typography color="darkred">Discount Code: {discountCode}</Typography>
                <Typography color="darkred">{`- ${convertToCurrency(discount)}`}</Typography>
              </Box>
            )}
            {isUsingPromoCode && !discount && (
              <Box className={classes.promoCode}>
                <TextField
                  label="Promo Code"
                  size="small"
                  variant="outlined"
                  value={promoInput}
                  onChange={(e) => setPromoInput(e.target.value)}
                />
                {promoInput && (
                  <Button variant="contained" onClick={applyPromoCode}>
                    {isPromoLoading ? 'Loading...' : 'Apply'}
                  </Button>
                )}
                {discount === 0 && promoCode && !isPromoLoading && (
                  <Typography variant="caption" color="darkred">
                    Invalid Promo Code
                  </Typography>
                )}
              </Box>
            )}
            {!isUsingPromoCode && (
              <Button
                variant="text"
                sx={{ alignSelf: 'start', position: 'relative', right: 8, textTransform: 'none' }}
                onClick={() => setIsUsingPromoCode(true)}
              >
                Use Promo Code
              </Button>
            )}
            <Divider
              sx={{
                opacity: '0.6',
              }}
            />
            {appliedCredit !== 0 && (
              <>
                <Box className={classes.paymentItem}>
                  <Typography color="green">Credit To Be Applied</Typography>
                  <Typography color="green">{convertToCurrency(-appliedCredit)}</Typography>
                </Box>
                {parseInt(finalAmount, 10) === 1 && (
                  <Typography variant="caption" alignSelf="center" color="darkred">
                    Maximum discount applied, the rest of your credit can be used on the next purchase.
                  </Typography>
                )}
                <Divider
                  sx={{
                    opacity: '0.6',
                  }}
                />
              </>
            )}
            <Box className={classes.paymentTotal}>
              <Typography fontWeight="bold">Total</Typography>
              <Typography fontWeight="bold">{convertToCurrency(finalAmount)}</Typography>
            </Box>
          </Box>
          {!isCheckoutLoading && (
            <>
              {!isConsultation && treatmentsToRead.length !== 0 && (
                <>
                  <Typography className={classes.termsText} variant="body1" gutterBottom>
                    <Checkbox checked={termsRead || false} onClick={handleTermsRead} />I have read the{' '}
                    <LowerCaseButton
                      variant="text"
                      onClick={() => {
                        setModalOpen(true);
                      }}
                      classes={{
                        root: classes.root,
                      }}
                    >
                      treatment information
                    </LowerCaseButton>
                  </Typography>
                  <StyledModal open={modalOpen}>
                    <Grid item className={classes.modalGrid}>
                      <InformationModal setModalOpen={setModalOpen} selectedTreatments={treatmentsToRead} />
                    </Grid>
                  </StyledModal>
                  {shippingAlert && (
                    <Typography className={classes.shippingAlert} variant="body2" color="secondary" gutterBottom>
                      {shippingAlert}
                    </Typography>
                  )}
                </>
              )}
              <Button
                variant="contained"
                disabled={
                  (!termsRead && !isConsultation) ||
                  (scriptMode && !pharmacyConsentRead) ||
                  (!scriptMode && isPharmacyPending)
                }
                onClick={handlePaymentButton}
              >
                {isPaymentLoading ? 'Loading...' : 'Proceed'}
              </Button>
            </>
          )}
        </>
      )}
      {!products && <CircularProgress />}
    </StyledGrid>
  );
};

export default PaymentDetails;
