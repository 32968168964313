import React, { useContext, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Grid, CircularProgress, Stack, Typography, Button } from '@mui/material';
import { doc, getDoc, onSnapshot, setDoc } from 'firebase/firestore';

import { httpsCallable } from 'firebase/functions';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from '../../../auth-context';
import { db, functions } from '../../../firebase-config';
import { APP_BASE_URL } from '../../../utils/constants';
import { scriptPriceTranslator } from '../../../utils/form-translators';
import { getPaymentSuccessUrl, logFirstConsultation } from './paymentHelpers';
import ModalContainer from '../../layout/modal-container';

const PREFIX = 'NovattiPayment';

const classes = {
  modalGrid: `${PREFIX}-modalGrid`,
  paper: `${PREFIX}-paper`,
  iFrame: `${PREFIX}-iFrame`,
  container: `${PREFIX}-container`,
};

const Root = styled('div')(() => ({
  [`& .${classes.modalGrid}`]: {
    '@media (max-width: 600px)': {
      padding: '10px 0px',
    },
    textAlign: 'center',
    borderRadius: '5px',
  },

  [`& .${classes.paper}`]: {
    margin: '10px 10px 40px 10px',
    padding: '30px',
  },

  [`& .${classes.iFrame}`]: {
    width: '100%',
    height: '24rem',
    border: 0,
    overflow: 'hidden',
  },

  [`& .${classes.container}`]: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const MAINTENANCE_MODE = false;

const NovattiPayment = ({
  type,
  formName,
  isPickup,
  promoCode,
  scriptMode: isScriptMode,
  productIds,
  selectedTreatments,
}) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [error, setError] = React.useState(false);
  const [iFrameUrl, setIFrameUrl] = React.useState('');
  // NOTE: Not sure why producdIds come first, also unsure why we have both productIds and selectedTreatments
  const itemsSelected = productIds.length ? productIds : selectedTreatments;
  const [isOpenMaintenanceModal, setIsOpenMaintenanceModal] = React.useState(true);

  const priceIds = isScriptMode ? [scriptPriceTranslator[formName]] : itemsSelected;

  const cleanedProductIds = priceIds.map((id) => id.replace('price_', ''));

  useEffect(() => {
    let unsubOrderPaymentCheck;
    let unsubLastPurchaseCheck;
    const initialize = async () => {
      if (!user) {
        return;
      }

      const { data: products } = await httpsCallable(
        functions,
        'getProducts',
      )({
        body: {
          productIds: cleanedProductIds,
        },
      });

      const paymentSuccessRoute = getPaymentSuccessUrl({ productName: products?.[0]?.name, isScriptMode });

      const userData = (await getDoc(doc(db, 'patients', user.uid))).data();

      const { data: orderData } = await httpsCallable(
        functions,
        'novattiCreateOrder',
      )({
        body: {
          isPickup,
          productIds: cleanedProductIds,
          client: {
            email: userData?.email,
            first_name: userData?.firstName,
            last_name: userData?.lastName,
            personal_code: user.uid,
            id: user?.uid,
          },
          formName,
          success_redirect: `${APP_BASE_URL}${paymentSuccessRoute}`,
          discountCode: promoCode,
        },
      });

      if (orderData.error || !orderData.id || !orderData.iframe_checkout) {
        // Uncomment once Sentry is added to the project
        // Sentry.captureMessage('Error creating order', { level: 'error', extras: { error: orderData?.error, id: orderData?.id, cloudFunction: "novattiCreateOrder" } });
        setError(orderData.error || 'Error creating order. Please contact support.');
        return;
      }

      await setDoc(doc(db, 'patients', user.uid, 'novattiOrders', orderData.id), orderData);

      unsubOrderPaymentCheck = onSnapshot(doc(db, 'patients', user.uid, 'novattiOrders', orderData.id), (snapshot) => {
        const novattiOrder = snapshot.data();

        if (novattiOrder?.status === 'paid') {
          // NOTE: We are making a lot of assumptions about the data here.
          // We're currently only logging for consults, which are `name: 'consultation'` in the database.
          // Can we remove to allow for all products?

          logFirstConsultation({ productPurchased: type, products, userId: user?.uid });
          navigate(paymentSuccessRoute);
        }
      });
      setIFrameUrl(orderData.iframe_checkout);
    };

    initialize();
    return () => {
      if (unsubOrderPaymentCheck) unsubOrderPaymentCheck();
      if (unsubLastPurchaseCheck) unsubLastPurchaseCheck();
    };
  }, [formName, productIds, isScriptMode, selectedTreatments, type, isPickup, user, promoCode]);

  return (
    <Root>
      {MAINTENANCE_MODE && (
        <ModalContainer isOpen={isOpenMaintenanceModal} setIsOpen={setIsOpenMaintenanceModal}>
          <Stack p={2} alignItems="center">
            <Typography fontWeight="bold" color="primary">
              Current Payment Issues
            </Typography>
            <Typography maxWidth={360} mt={2} textAlign="justify">
              We are aware some users are running into errors at checkout.
            </Typography>
            <Typography maxWidth={360} mt={2} textAlign="justify">
              Our payment provider is experiencing an outage and they are working to have this resolved for us asap.
            </Typography>
            <Typography maxWidth={360} mt={2} textAlign="justify">
              Thank you for your patience. If your payment does not work we will email you when things are back to
              normal.
            </Typography>
            <Button sx={{ mt: 4 }} onClick={() => setIsOpenMaintenanceModal(false)}>
              Close
            </Button>
          </Stack>
        </ModalContainer>
      )}
      <Grid className={classes.container}>
        {iFrameUrl && !error && <iframe src={iFrameUrl} title="Novatti" className={classes.iFrame} scrolling="no" />}
        {!iFrameUrl && !error && <CircularProgress />}
        {error && (
          <Stack>
            <Typography fontWeight="bold" color="primary">
              Sorry for the inconvenience.
            </Typography>
            <Typography maxWidth={360}>{error}</Typography>
          </Stack>
        )}
      </Grid>
    </Root>
  );
};

NovattiPayment.propTypes = {
  productIds: PropTypes.arrayOf(PropTypes.string),
  selectedTreatments: PropTypes.arrayOf(PropTypes.string),
  isPickup: PropTypes.bool.isRequired,
  scriptMode: PropTypes.bool.isRequired,
  formName: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  promoCode: PropTypes.string,
};

// NOTE: productIds and selectedTreatments look to be the same. List of CatalogueID or `price_${CatalogueID}`
// TODO: Refactor to accept only one of them.
NovattiPayment.defaultProps = {
  productIds: [],
  selectedTreatments: [],
  promoCode: '',
};

export default NovattiPayment;
