import * as Sentry from '@sentry/react';

import { logPurchaseEvent } from '../../../analytics/logAnalyticsEvent';

// Maps to Catalogue.name in Firestore
export const SERVICES = {
  CONSULT: 'consultation',
  TRANSFER_SCRIPT: 'script admin fee',
  REBOOK_MISSED_APPOINTMENT: 'booking fee',
};

export const PAYMENT_URLS = {
  [SERVICES.CONSULT]: '/consult-payment-success',
  [SERVICES.REBOOK_MISSED_APPOINTMENT]: '/booking-payment-success',
  [SERVICES.TRANSFER_SCRIPT]: '/script-payment-success',
  default: '/payment-success',
};

/**
 * Get success page URL based on product name.
 * @param {object} params - Parameters.
 * @param {string} params.productName - Name of the product.
 * @param {boolean} [params.isScriptMode=false] - Indicates if the script mode is active.
 * @returns {string} - URL of the success page.
 */
export const getPaymentSuccessUrl = ({ productName, isScriptMode = false }) => {
  // QUESTION: FIXME: Why don't we check for "script admin fee"/SERVICES.TRANSFER_SCRIPT
  // instead of allowing scritMode to override the default?
  if (isScriptMode) {
    return PAYMENT_URLS[SERVICES.TRANSFER_SCRIPT];
  }

  return PAYMENT_URLS[productName] || PAYMENT_URLS.default;
};

/**
 * Logs the first consultation purchase event. Will only log if productPurchased is 'consult'.
 *
 * @param {Object} options - The options for logging the event.
 * @param {string} options.productPurchased - The product purchased.
 * @param {Array} options.products - The list of products.
 * @param {string} options.userId - The user ID.
 */
export const logFirstConsultation = ({ productPurchased, products = [], userId }) => {
  const consultation = products?.find((product) => product.name === SERVICES.CONSULT) || {
    price: 0,
    name: 'UNKNOWN',
    form: 'UNKNOWN',
    stripePrice: 'NOT_A_CONSULTATION',
  };

  const catalogId = consultation.stripePrice.replace('price_', '');

  if (productPurchased !== 'consult') {
    Sentry.captureMessage('[logFirstConsultation] - productPurchased is not a consultation', {
      level: 'debug',
      extra: { productPurchased, userId },
    });
    return;
  }

  logPurchaseEvent({
    userId,
    catalogId,
    value: consultation.price,
    itemsPurchased: [
      { itemId: catalogId, itemName: `${consultation.form} ${consultation.name}`, price: consultation.price },
    ],
  });
};
