import React from 'react';
import PropTypes from 'prop-types';
import { Box, CssBaseline } from '@mui/material';

import Navbar from './navbar';
import Sidebar from './sidebar';
import UnauthenticatedRouter from './unauthenticated-router';
import AuthenticatedRouter from './authenticated-router';

const DRAWER_WIDTH = 200;

// QUESTION: Why does unauthenticated not have a Sidebar?
export const UnauthenticatedLayout = () => (
  <>
    <Navbar />
    <UnauthenticatedRouter />
  </>
);

export const VerifiedUserLayout = ({ handleLogout }) => (
  <Box id="mainRoot" sx={{ display: 'flex', height: '100dvh' }}>
    <CssBaseline />
    <Box
      component="nav"
      sx={{
        width: { sm: DRAWER_WIDTH },
        flexShrink: { sm: 0 },
        '@media (max-width: 1000px)': {
          display: 'none',
        },
      }}
    >
      <Sidebar
        handleLogoutClose={handleLogout}
        drawerWidth={DRAWER_WIDTH}
        open={undefined}
        onClose={undefined}
        from={undefined}
      />
    </Box>
    <Box
      id="mainContent"
      component="main"
      sx={{
        flexGrow: 1,
        width: { xs: '100%', sm: `calc(100% - ${DRAWER_WIDTH}px)` },
        height: '100dvh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <Navbar />
      <AuthenticatedRouter />
    </Box>
  </Box>
);

VerifiedUserLayout.propTypes = {
  handleLogout: PropTypes.func.isRequired,
};
