import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Paper, Container, Typography, Button } from '@mui/material';

const PREFIX = 'WarningFormElement';

const classes = {
  grid: `${PREFIX}-grid`,
  paper: `${PREFIX}-paper`,
  heading: `${PREFIX}-heading`,
  button: `${PREFIX}-button`
};

const StyledContainer = styled(Container)(() => ({
  [`& .${classes.grid}`]: {
    minHeight: '60vh',
    marginTop: 40,
  },

  [`& .${classes.paper}`]: {
    padding: '40px',
    width: '85%',
    maxWidth: '500px',
    '@media (max-width: 600px)': {
      padding: '30px',
    },
  },

  [`& .${classes.heading}`]: {
    marginBottom: 30,
  },

  [`& .${classes.button}`]: {
    margin: '30px auto 0px auto',
    width: '130px',
    display: 'block',
  }
}));

const WarningFormElement = (props) => {
  const { currentFormObject, handleNext } = props;



  return (
    <StyledContainer>
      <Grid
        container
        className={classes.grid}
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Paper className={classes.paper}>
          {currentFormObject.headingText && (
            <div>
              <Typography className={classes.heading} variant="h5" align="center" gutterBottom>
                {currentFormObject.headingText}
              </Typography>
            </div>
          )}
          <div>{currentFormObject.subheadingText}</div>
          <div>
            <Button className={classes.button} variant="contained" color="primary" onClick={handleNext}>
              Next
            </Button>
          </div>
        </Paper>
      </Grid>
    </StyledContainer>
  );
};

export default WarningFormElement;
