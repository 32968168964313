import React from 'react';
import { Grid, Typography } from '@mui/material';

const FlowerInfo = () => (
  <Grid item>
    <Typography variant="h6">Using medicinal cannabis flower</Typography>
    <br />
    <Typography variant="body1">
      Cannatrek dried flower medications are designed for inhalation and are dosed via a vaporiser. Vaporisation is the
      recommended method for inhaling dried medical cannabis flower.
      <br />
      <br />
      Recommended = Vaporisation
      <br />
      <br />
      A vaporiser is a device that heats dried medical cannabis without causing combustion. Vaporisation produces a
      vapour rich in cannabinoids that can be inhaled directly. There are several different models of vaporisers that
      patients can explore to see which one works best for them. Some points on vaporisation:
      <br />
      - Much more efficient than smoking.
      <br />
      - Does not produce undesirable compounds that can cause lung irritation. • A typical vaporizer bowl holds between
      0.1 to 0.25 gram.
      <br />
      <br />
      Not Recommended = Combustion
      <br />
      <br />
      Combustion refers to several different methods including a pipe or rolled cannabis cigarette (‘joint’). Combustion
      is not recommended as smoking can contribute to COPD (chronic obstructive pulmonary disease) and produces
      undesirable compounds that can be irritating to the lungs. Some things to consider related to combustion:
      <br />
      - Up to 30% of the active ingredients are lost in side stream smoke and combustion.
      <br />
      - A typical cannabis joint contains between 0.5 to 1 gram.
      <br />
      <br />
      Titration is important when using cannabis, especially for new patients. When cannabis is inhaled, cannabinoids
      are absorbed from the lungs into the bloodstream within minutes. In the bloodstream, the cannabinoids are
      transported to the brain. Maximum concentrations of cannabinoids in the brain are achieved in less than 15
      minutes, correlating with peak psychological and physiological effects. Dosing schedules differ for each patient
      and should be discussed with your doctor. Doses should be increased gradually over time and the effect fully
      assessed before continuing to increase. Note that consuming too much cannabis can result in discomfort and should
      be avoided.
    </Typography>
    <br />
  </Grid>
);

export default FlowerInfo;
