import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { Button, Container, Grid, Paper, TextField, Typography, Box, CircularProgress } from '@mui/material';
import {
  getAuth,
  getMultiFactorResolver,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
} from 'firebase/auth';

import CustomizableSnackbar from '../layout/snackbar';
import { ERROR_MESSAGES } from '../../utils/error-messages';

const PREFIX = 'SecondFactorLogin';

const classes = {
  paper: `${PREFIX}-paper`,
  removeContextPaper: `${PREFIX}-removeContextPaper`,
  updateEmailPaper: `${PREFIX}-updateEmailPaper`,
  gridContainer: `${PREFIX}-gridContainer`,
  removeContextGridContainer: `${PREFIX}-removeContextGridContainer`,
  recaptcha: `${PREFIX}-recaptcha`,
  verification: `${PREFIX}-verification`
};

const StyledContainer = styled(Container)(() => ({
  [`& .${classes.paper}`]: {
    padding: '40px 20px',
    width: '90%',
    maxWidth: '400px',
  },

  [`& .${classes.removeContextPaper}`]: {
    padding: '40px 20px',
    width: '90%',
    maxWidth: '400px',
  },

  [`& .${classes.updateEmailPaper}`]: {
    padding: '20px',
    boxShadow: 'unset',
  },

  [`& .${classes.gridContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '75vh',
  },

  [`& .${classes.removeContextGridContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.recaptcha}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '16px 0',
  },

  [`& .${classes.verification}`]: {
    display: 'flex',
    gap: 8,
    marginTop: 16,
    flexDirection: 'column',
    alignItems: 'stretch',
  }
}));

const SecondFactorLogin = ({
  error,
  removeContext,
  removeNumber,
  handleUpdateEmail,
  handleUpdatePassword,
  forgotPasswordUpdate,
}) => {

  const [mfaResolver, setMfaResolver] = useState(null);
  const [verificationId, setVerificationId] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [lastSent, setLastSent] = useState(Date.now());
  const [remainingCooldown, setRemainingCooldown] = useState(0);
  const [isTwoFactorAuthError, setIsTwoFactorAuthError] = useState(false);

  useEffect(() => {
    const cooldownMs = 35000;
    const interval = setInterval(() => {
      setRemainingCooldown(Math.floor((cooldownMs - (Date.now() - lastSent)) / 1000));
    }, 1000);
    return () => {
      console.log('Clearing interval');
      clearInterval(interval);
    };
  }, [lastSent, verificationId]);

  useEffect(() => {
    setVerificationId('');
    setTimeout(() => {
      const auth = getAuth();
      console.log('Initializing Two Factor', auth);
      const newMfaResolver = getMultiFactorResolver(auth, error);
      // FIX: Bug if we try to call this and RecapchaVerifier already exists in our web app
      const recaptchaVerifier = new RecaptchaVerifier(auth, `recaptcha-${lastSent}`, { size: 'invisible' });
      if (newMfaResolver.hints[0].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
        const phoneInfoOptions = {
          multiFactorHint: newMfaResolver.hints[0],
          session: newMfaResolver.session,
        };
        if (recaptchaVerifier) {
          const phoneAuthProvider = new PhoneAuthProvider(auth);
          console.log('Sending SMS...');
          phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier).then((id) => {
            setVerificationId(id);
          });
        }
      }
      setMfaResolver(newMfaResolver);
    }, 1000);
  }, [error, removeContext, removeNumber, handleUpdateEmail, handleUpdatePassword, forgotPasswordUpdate, lastSent]);

  const submitCode = () => {
    try {
      const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      // Complete sign-in.
      mfaResolver
        ?.resolveSignIn(multiFactorAssertion)
        .then((userCredential) => {
          if (removeContext) removeNumber();
          if (handleUpdateEmail) handleUpdateEmail(true);
          if (handleUpdatePassword) handleUpdatePassword(true);
          if (forgotPasswordUpdate) forgotPasswordUpdate(true);
        })
        .catch((err) => {
          Sentry.captureException(err);
          setIsTwoFactorAuthError(true);
        });
    } catch (e) {
      Sentry.captureException(e);
      setSnackbarOpen(true);
    }
  };

  const resendSMS = () => {
    setLastSent(Date.now());
  };

  return (
    <StyledContainer maxWidth="md">
      <Grid
        container
        className={
          removeContext || handleUpdateEmail || handleUpdatePassword
            ? classes.removeContextGridContainer
            : classes.gridContainer
        }
        spacing={0}
      >
        <Paper className={handleUpdateEmail || handleUpdatePassword ? classes.updateEmailPaper : classes.paper}>
          {!verificationId && (
            <Box className={classes.recaptcha}>
              <div id={`recaptcha-${lastSent}`} />
              <CircularProgress />
            </Box>
          )}
          {verificationId && (
            <>
              <Typography>Please enter the 6-digit code sent to your device</Typography>
              <Box className={classes.verification}>
                <TextField
                  value={verificationCode}
                  fullWidth
                  size="small"
                  label="Verification Code"
                  onChange={(e) => setVerificationCode(e.target.value)}
                  error={isTwoFactorAuthError}
                  helperText={isTwoFactorAuthError ? ERROR_MESSAGES.TWO_FACTOR_AUTH : ''}
                />
                <Button variant="text" onClick={resendSMS} disabled={remainingCooldown > 0}>
                  {remainingCooldown > 0 ? `You can resend the SMS in ${remainingCooldown} seconds` : 'Resend SMS'}
                </Button>
                <Button fullWidth variant="contained" onClick={submitCode}>
                  Submit
                </Button>
              </Box>
            </>
          )}
        </Paper>
      </Grid>
      <CustomizableSnackbar
        message="Code Error: Please try again"
        snackbarOpen={snackbarOpen}
        setSnackbarOpen={setSnackbarOpen}
      />
    </StyledContainer>
  );
};

SecondFactorLogin.propTypes = {
  error: PropTypes.shape({
    name: PropTypes.string,
    code: PropTypes.string,
    message: PropTypes.string,
  }),
  removeContext: PropTypes.bool,
  removeNumber: PropTypes.func,
  handleUpdateEmail: PropTypes.func,
  handleUpdatePassword: PropTypes.func,
};

SecondFactorLogin.defaultProps = {
  error: null,
  removeContext: false,
  handleUpdatePassword: () => {},
  handleUpdateEmail: () => {},
  removeNumber: () => {},
};

export default SecondFactorLogin;
